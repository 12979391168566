export function decodeEntities(text: string) {
  if (text) {
    const entities = [
      { entity: '&#261;', text: 'ą' },
      { entity: '&#380;', text: 'ż' },
      { entity: '&#378;', text: 'ź' },
      { entity: '&#263;', text: 'ć' },
      { entity: '&#324;', text: 'ń' },
      { entity: '&#347;', text: 'ś' },
      { entity: '&#322;', text: 'ł' },
      { entity: '&#281;', text: 'ę' },
      { entity: '&#243;', text: 'ó' }
    ];
    entities.forEach(element => {
      text = text.replace(new RegExp(element.entity, 'gi'), element.text);
    });
  }
  return text;
}

export function getServerLink(link: string) {
  return 'http://' + link;
}

export function showAsInnerHtml(str: string) {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div.textContent || div.innerText;
}
