import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageElementsService {
  constructor(private httpClient: HttpClient) {}

  getPage(collectionKey: string) {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${environment.apiUrl}/cms?collection=${escape(collectionKey)}&language=EN`);
  }

  addPageElement(data: any) {
    return this.httpClient.post(`${environment.apiUrl}/cms/keys`, data);
  }

  updatePageElement(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/cms/${id}`, data);
  }

  getPageElement(collectionKey: string, collectionElement: string) {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${environment.apiUrl}/cms/keys?collection=${escape(collectionKey)}&key=${escape(collectionElement)}&language=EN`);
  }

  deletePageElement(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}/cms/${id}`);
  }

  getPageElementImages(id: number) {
    return this.httpClient.get(`${environment.apiUrl}/cms/${id}/keyImages`);
  }

  addPageElementImage(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}/cms/${id}/keyImages`, data);
  }

  deletePageElementImage(id: number, imageId: number) {
    return this.httpClient.delete(`${environment.apiUrl}/cms/${id}/keyImages/${imageId}`);
  }
}
